
// Fonts
@import url(https://fonts.googleapis.com/css?family=Raleway:300,400,600);

// Variables
//@import "variables";
@import "node_modules/bootstrap/scss/_variables";

// Bootstrap
//@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";
@import "node_modules/bootstrap/scss/bootstrap";


@import "inputrange";

@import "animate";

@import "font-awesome.css";

.no-select:focus {
  -webkit-touch-callout: none !important;
  -webkit-user-select: none !important;
  -khtml-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
  outline: none;
}